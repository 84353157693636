import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import {
  Home,
  Users,
  DollarSign,
  UserCircle,
  LogOut,
  Calendar,
  BookOpen,
  GraduationCap
} from 'lucide-react';
import { cn } from '../../lib/utils';

export function BottomNav() {
  const { user, logout } = useAuthStore();

  if (!user) return null;

  const getNavItems = () => {
    switch (user.role) {
      case 'admin':
        return [
          { to: '/', icon: Home, label: 'Início' },
          { to: '/users', icon: Users, label: 'Usuários' },
          { to: '/schedules', icon: Calendar, label: 'Schedules' },
          { to: '/finances', icon: DollarSign, label: 'Finanças' },
          { to: '/profile', icon: UserCircle, label: 'Perfil' },
        ];
      case 'teacher':
        return [
          { to: '/', icon: Home, label: 'Home' },
          { to: '/schedule', icon: Calendar, label: 'Schedule' },
          { to: '/students', icon: Users, label: 'Students' },
          { to: '/profile', icon: UserCircle, label: 'Profile' },
        ];
      case 'student':
        return [
          { to: '/', icon: Home, label: 'Início' },
          { to: '/schedule', icon: Calendar, label: 'Schedule' },
          { to: '/exercises', icon: BookOpen, label: 'Exercícios' },
          { to: '/profile', icon: UserCircle, label: 'Profile' },
        ];
      default:
        return [];
    }
  };

  const navItems = getNavItems();

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-[#0e3e4e] text-white h-14 shadow-lg">
      <div className="flex justify-around items-center h-full max-w-lg mx-auto">
        {navItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className={({ isActive }) =>
              cn(
                'flex flex-col items-center justify-center p-1 w-16 hover:bg-[#1a5268] transition-colors',
                isActive && 'bg-[#1a5268]'
              )
            }
          >
            <item.icon className="h-4 w-4 mb-0.5" />
            <span className="text-[10px]">{item.label}</span>
          </NavLink>
        ))}
        <button
          onClick={logout}
          className="flex flex-col items-center justify-center p-1 w-16 hover:bg-[#1a5268] transition-colors text-red-400"
        >
          <LogOut className="h-4 w-4 mb-0.5" />
          <span className="text-[10px]">Sair</span>
        </button>
      </div>
    </nav>
  );
}