import { create } from 'zustand';
import { 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  User as FirebaseUser,
  AuthError,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db, getAuthErrorMessage } from '../lib/firebase';
import type { User, UserRole } from '../types/user';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  isLoading: true, // Start as true to show loading state while checking auth
  error: null,
  login: async (email: string, password: string) => {
    try {
      set({ isLoading: true, error: null });
      
      // Enable persistent login
      await setPersistence(auth, browserLocalPersistence);
      
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Query users collection by email instead of uid
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('User data not found');
      }

      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data() as Omit<User, 'id'>;
      
      set({
        user: { id: userDoc.id, ...userData },
        isAuthenticated: true,
        isLoading: false,
        error: null
      });

      // Return without throwing error to indicate successful login
      return;
    } catch (error) {
      console.error('Login error:', error);
      const authError = error as AuthError;
      set({
        user: null,
        isAuthenticated: false,
        error: getAuthErrorMessage(authError.code),
        isLoading: false
      });
      throw error;
    }
  },
  logout: async () => {
    try {
      set({ isLoading: true, error: null });
      await signOut(auth);
      set({ 
        user: null, 
        isAuthenticated: false,
        isLoading: false,
        error: null
      });
    } catch (error) {
      const authError = error as AuthError;
      set({ 
        error: getAuthErrorMessage(authError.code),
        isLoading: false
      });
    }
  },
  changePassword: async (currentPassword: string, newPassword: string) => {
    try {
      set({ isLoading: true, error: null });
      
      const firebaseUser = auth.currentUser;
      if (!firebaseUser?.email) {
        throw new Error('No user is currently logged in');
      }

      const credential = EmailAuthProvider.credential(
        firebaseUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(firebaseUser, credential);
      await updatePassword(firebaseUser, newPassword);
      
      set({ isLoading: false, error: null });
    } catch (error) {
      const authError = error as AuthError;
      set({
        error: getAuthErrorMessage(authError.code),
        isLoading: false
      });
      throw error;
    }
  },
  clearError: () => set({ error: null })
}));

// Set up auth state listener
onAuthStateChanged(auth, async (firebaseUser: FirebaseUser | null) => {
  if (!firebaseUser) {
    useAuthStore.setState({ 
      user: null, 
      isAuthenticated: false, 
      isLoading: false,
      error: null
    });
    return;
  }

  try {
    // Query users collection by email
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', firebaseUser.email));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data() as Omit<User, 'id'>;
      useAuthStore.setState({
        user: { id: userDoc.id, ...userData },
        isAuthenticated: true,
        isLoading: false,
        error: null
      });
    }
  } catch (error) {
    const authError = error as AuthError;
    useAuthStore.setState({
      user: null,
      isAuthenticated: false,
      error: getAuthErrorMessage(authError.code),
      isLoading: false
    });
  }
});