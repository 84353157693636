import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';

// Lazy load pages
const Landing = React.lazy(() => import('../pages/Landing'));
const Login = React.lazy(() => import('../pages/Login'));
const AdminDashboard = React.lazy(() => import('../pages/admin/Dashboard'));
const Users = React.lazy(() => import('../pages/admin/Users'));
const Schedules = React.lazy(() => import('../pages/admin/Schedules'));
const Finances = React.lazy(() => import('../pages/admin/Finances'));
const Profile = React.lazy(() => import('../pages/admin/Profile'));
const TeacherCalendar = React.lazy(() => import('../pages/schedule/TeacherCalendar'));
const TeacherHome = React.lazy(() => import('../pages/teacher/Home'));
const TeacherSchedule = React.lazy(() => import('../pages/teacher/Schedule'));
const TeacherStudents = React.lazy(() => import('../pages/teacher/Students'));
const TeacherProfile = React.lazy(() => import('../pages/teacher/Profile'));
const StudentHome = React.lazy(() => import('../pages/student/Home'));
const StudentSchedule = React.lazy(() => import('../pages/student/Schedule'));
const StudentClasses = React.lazy(() => import('../pages/student/Classes'));
const StudentExercises = React.lazy(() => import('../pages/student/Exercises'));
const ListeningExercises = React.lazy(() => import('../pages/student/exercises/Listening'));
const WritingExercises = React.lazy(() => import('../pages/student/exercises/Writing'));
const SpeakingExercises = React.lazy(() => import('../pages/student/exercises/Speaking'));
const ConnectorsExercises = React.lazy(() => import('../pages/student/exercises/Connectors'));
const StudentProfile = React.lazy(() => import('../pages/student/Profile'));
const AiTeacher = React.lazy(() => import('../pages/student/AiTeacher'));
const NotFound = React.lazy(() => import('../pages/NotFound'));

export function AppRoutes() {
  const { isAuthenticated, user } = useAuthStore();

  // Show landing page for non-authenticated users
  if (!isAuthenticated) {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </React.Suspense>
    );
  }

  // Admin routes
  if (user?.role === 'admin') {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/schedules" element={<Schedules />} />
          <Route path="/finances" element={<Finances />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/teacher/:teacherId/calendar" element={<TeacherCalendar />} />
          <Route path="/teacher/:teacherId" element={<TeacherHome />} />
          <Route path="/" element={<Navigate to="/admin" replace />} />
          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Routes>
      </React.Suspense>
    );
  }

  // Rest of the routes remain the same...
  // Teacher routes
  if (user?.role === 'teacher') {
    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/teacher" element={<TeacherHome />} />
          <Route path="/schedule" element={<TeacherSchedule />} />
          <Route path="/students" element={<TeacherStudents />} />
          <Route path="/profile" element={<TeacherProfile />} />
          <Route path="/" element={<Navigate to="/teacher" replace />} />
          <Route path="*" element={<Navigate to="/teacher" replace />} />
        </Routes>
      </React.Suspense>
    );
  }

  // Student routes (default)
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<StudentHome />} />
        <Route path="/schedule" element={<StudentSchedule />} />
        <Route path="/exercises" element={<StudentExercises />} />
        <Route path="/exercises/listening" element={<ListeningExercises />} />
        <Route path="/exercises/writing" element={<WritingExercises />} />
        <Route path="/exercises/speaking" element={<SpeakingExercises />} />
        <Route path="/exercises/connectors" element={<ConnectorsExercises />} />
        <Route path="/ai-teacher" element={<AiTeacher />} />
        <Route path="/profile" element={<StudentProfile />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </React.Suspense>
  );
}