import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  setPersistence, 
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence
} from 'firebase/auth';
import { 
  getFirestore, 
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyC8z3wuhp04-qVLMxkmm5QKbUbrlOuQvfA",
  authDomain: "unique-idiomas-f30ed.firebaseapp.com",
  projectId: "unique-idiomas-f30ed",
  storageBucket: "unique-idiomas-f30ed.firebasestorage.app",
  messagingSenderId: "360735063666",
  appId: "1:360735063666:web:13a441406556cede724ceb",
  measurementId: "G-6K80MECTCH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with persistence
const auth = getAuth(app);

// Enable persistent auth state
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error('Auth persistence error:', error);
  });

// Initialize Firestore with settings for better offline support
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  console.error('Error enabling offline persistence:', err);
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled in one tab at a time
    console.warn('Multiple tabs open, offline persistence only works in one tab at a time');
  } else if (err.code === 'unimplemented') {
    // The current browser doesn't support persistence
    console.warn('Current browser doesn\'t support offline persistence');
  }
});

// Initialize other services
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// Export configured Firestore instance
export { db, auth };

// Error mapping for better user messages
export const getAuthErrorMessage = (code: string): string => {
  switch (code) {
    case 'auth/user-not-found':
      return 'No user found with this email address';
    case 'auth/wrong-password':
      return 'Invalid password';
    case 'auth/invalid-email':
      return 'Invalid email address';
    case 'auth/user-disabled':
      return 'This account has been disabled';
    case 'auth/email-already-in-use':
      return 'This email is already registered';
    case 'auth/operation-not-allowed':
      return 'Email/password accounts are not enabled';
    case 'auth/weak-password':
      return 'Password should be at least 6 characters';
    default:
      return 'An error occurred during authentication';
  }
};