import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';

// Only register service worker in production
if (import.meta.env.PROD && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(registration => {
      // Check for updates on page load
      registration.update();

      // Periodically check for updates (every 60 minutes)
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 60);

      // Handle updates
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        if (newWorker) {
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // New content is available
              const updatePrompt = document.createElement('div');
              updatePrompt.className = 'fixed bottom-4 left-4 right-4 bg-[#1a2b32] p-4 rounded-lg shadow-lg z-50 flex items-center justify-between';
              updatePrompt.innerHTML = `
                <div class="flex-1">
                  <p class="text-white">A new version is available!</p>
                  <p class="text-sm text-gray-400">Refresh to update the app</p>
                </div>
                <button class="bg-[#ffc149] text-[#1a2b32] px-4 py-2 rounded-lg hover:bg-[#ffb029] transition-colors ml-4">
                  Update Now
                </button>
              `;

              document.body.appendChild(updatePrompt);

              // Handle update click
              const updateButton = updatePrompt.querySelector('button');
              if (updateButton) {
                updateButton.addEventListener('click', () => {
                  newWorker.postMessage({ type: 'SKIP_WAITING' });
                  window.location.reload();
                });
              }
            }
          });
        }
      });
    });
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);